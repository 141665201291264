.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-blue-dark {
  color: #062656 !important;
}
.text-blue {
  color: #005bae !important;
}
.text-blue-light {
  color: #6cb5ee !important;
}
.text-grey {
  color: #6c757d !important;
}
.text-grey-new {
  color: #99B3C7 !important;
}
.text-grey-light {
  color: #c6cbd3 !important;
}
.text-success {
  color: #21bf73 !important;
}
.text-error {
  color: red !important;
}
.text-warning {
  color: #F87D17 !important;
}
.text-white {
  color: #ffffff !important;
}
.text-black {
  color: #2e2c34 !important;
}
.text-purple {
  color: #5542f6 !important;
}
.text-brown {
  color: #cc8414 !important;
}
.bg-color-brown-light {
  background-color: #fff1da;
}
.bg-color-white {
  background-color: #ffffff !important;
}
.bg-color-black {
  background-color: #000000 !important;
}
.bg-color-blue-light {
  background-color: #e7f3ff !important;
}
.bg-color-blue {
  background-color: #005bae !important;
}
.bg-color-grey-light {
  background-color: #cccccc !important;
}
.bg-color-grey-light1 {
  background-color: #F8F9FA !important;
}

.bg-success-light {
  background-color: #20c9ac1a !important;
}
.bg-color-green-light {
  background-color: #3aff6b3f !important;
}
.bg-color-success {
  background-color: #21BF73 !important;
}
.bg-color-error-light{
background-color: #FFECEF;

}

.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-28 {
  font-size: 28px !important;
}
.fs-40 {
  font-size: 40px !important;
}

.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-bold {
  font-weight: bold !important;
}
.fw-bold {
  font-weight: bolder !important;
}
.my-1 {
  margin-block: 1rem !important;
}

.mx-5px {
  margin-inline: 5px !important;
}
.mx-1 {
  margin-inline: 1rem !important;
}
.my-2 {
  margin-block: 2rem !important;
}
.my-8px {
  margin-block: 8px !important;
}
.mx-2 {
  margin-inline: 2rem !important;
}

.py-1 {
  padding-block: 1rem !important;
}
.px-1 {
  padding-inline: 1rem !important;
}
.py-2 {
  padding-block: 2rem !important;
}
.px-2 {
  padding-inline: 2rem !important;
}
.py-5px {
  padding-block: 5px !important;
}
.mouse-click {
  cursor: pointer !important;
}
.clickable-effect {
  background-color: #ffffff;
}
.clickable-effect:hover {
  background-color: #d7e3ee !important;
  transition: background-color 1s;
}
.avatar-h-1 {
  height: 1rem !important;
  width: 1rem !important;
}

.avatar-h-2 {
  height: 2rem !important;
  width: 2rem !important;
}
.avatar-h-3 {
  height: 3rem !important;
  width: 3rem !important;
}
.avatar-h-4 {
  height: 4rem !important;
  width: 4rem !important;
}
.avatar-h-5 {
  height: 5rem !important;
  width: 5rem !important;
}
.border-radius-5 {
  border-radius: 5px !important;
}
.border-radius-10 {
  border-radius: 10px !important;
}
.border-radius-15 {
  border-radius: 15px !important;
}
.border-radius-round {
  border-radius: 50% !important;
}
.h-200 {
  height: 200px;
}
.h-32 {
  height: 32px !important;
}
.h-530 {
  height: 530px;
}
.h-82 {
  height: 82px;
}
.h-360 {
  height: 360px;
}
.h-400{
  height: 400px;
}
.h-163 {
  height: 163px;
}
.h-434 {
  height: 434px;
}
.overflow-scroll {
  overflow-y: auto !important;
}
.overflow-scroll::-webkit-scrollbar {
  display: none;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}
.overflow-y-hidden::-webkit-scrollbar {
  display: none;
}
.overflow-scroll-hidden {
  overflow: auto !important;
}
.overflow-scroll-hidden::-webkit-scrollbar {
  display: none !important;
}
.overflow-scroll-visible {
  overflow-y: auto !important;
}
.move-left-10 {
  position: relative;
  right: 10px;
}
.move-right-10 {
  position: relative;
  left: 10px;
}

.avatar-12px {
  height: 12px !important;
  width: 12px !important;
}

.min-width-650 {
  min-width: 650px;
}
.min-h-434 {
  min-height: 434px;
}

.p-2px {
  padding: 2px;
}
.p-5px {
  padding: 5px;
}
.move-down-5px {
  position: relative;
  top: 5px;
}
.max-width-112 {
  max-width: 112px !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  position: relative;
  left: 18px;
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(/src/assets/images/calendar.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}
.max-width-150 {
  max-width: 150px;
}
.min-width-120 {
  min-width: 120px !important;
}
.min-width-250 {
  min-width: 250px !important;
}
.p-inline-0 {
  padding-inline: 0px !important;
}
.min-h-250 {
  min-height: 250px !important;
}
.my-5px {
  margin-block: 8px !important;
}
.flex {
  display: flex !important;
}
.inline-flex {
  display: inline-flex !important;
}
.justify-center {
  justify-content: center !important;
}
.space-btw {
  justify-content: space-between !important;
}
.align-item {
  align-items: center !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.width-100 {
  width: 100% !important;
}
.h-40 {
  height: 40px !important;
}
.fs-32 {
  font-size: 32px !important;
}
@media only screen and (max-width: 600px) {
  .fs-32 {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .fs-32 {
    font-size: 24px !important;
  }
}
.min-width-540 {
  min-width: 540px !important;
}
.margin-bottom-0 {
  margin-bottom: 0px;
}
.p-0 {
  padding: 0px !important;
}

.profile-img {
  width: 220px;
  height: 177px;
}

@media screen and (max-width: 1024px) {
  .profile-img {
    width: 174px;
    height: 174px;
  }
}
.broker-img {
  height: 60px;
  width: 60px;
}

.w-30-per {
  width: 30%;
}
.min-width-160 {
  min-width: 160px;
}
.border-radius-24 {
  border-radius: 24px !important;
}
.visibility-hidden {
  visibility: hidden;
}
.w-32 {
  width: 32px;
}

.theme-input{
  height: 40px !important;
  border-radius: 5px !important;
  border: none;
  background-color: #F8F8F8;
  
  
}

.theme-input:active{
background-color: #ffffff;
border: solid;
border-color: #EEEEEE;
border-width: 1px;
}

.theme-input:focus-within{
  background-color: #ffffff;
border: solid;
border-color: #88CDFF;
border-width: 1px;
}
.m-b-2px{
  margin-bottom: 2px !important;
}

.h-w-18{
  height: 18px;
  width: 18px;
}
.animate{
  transform: scale(1);
transition: transform 150ms;
}
.animate:hover{
  cursor: pointer;
}
.animate:active{
  transform: scale(0.98);
  transition: transform 250ms;
}

.shadow{
  box-shadow: 2px 2px 7px 1px #e0dfdfd7 !important
}


.theme-input-v2{
  height: 40px !important;
  border-radius: 5px !important;
  border: solid;
  background-color: #ffffff;
  border-color: #EEEEEE;
border-width: 1px;
  
  
}

.theme-input-v2:active{
background-color: #ffffff;
border: solid;
border-color: #EEEEEE;
border-width: 1px;
}

.theme-input-v2:focus-within{
  background-color: #ffffff;
border: solid;
border-color: #88CDFF !important;
border-width: 1px;
}
.theme-input-v2:hover{
  background-color: #ffffff;
border: solid;
border-color: #141414;
border-width: 1px;
}

.w-100-percent{
  width: 100% !important; 
}

.scrollable-div {
  overflow-x: auto !important;
  overflow-y: hidden !important;
  white-space: nowrap !important;
}
.scrollable-div::-webkit-scrollbar {
  display: none;
}
.text-unset{
  text-transform: unset !important;
}

.approve-button-reject{
  background-color: #f8f9fa;
  border-style:solid;
  border-width: 1px;
  border-color: #f1f1f1;
  border-radius: 4px;
  height: 26px;
  min-width: 43px;
  padding-inline: 12px;
  
}
.approve-button-reject:hover{
background-color: #FFECEF;
border-color: #D90429;
}
.approve-button{
  background-color: #f8f9fa;
  border-style:solid;
  border-width: 1px;
  border-radius: 4px;
  height: 26px;
  min-width: 43px;
  border-color: #f1f1f1;
 padding-inline: 12px;
}
.approve-button:hover{
background-color: #F1FEF8;
border-color: #21BF73;
}
.z-index-high{
  z-index: 1000 !important;
}
.avatar-90{
  height:91px !important;
   width:91px !important;
}
.border-color-grey{
  border-color:#F1F1F1 !important ;
}

.center-items{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.left-12px{
  position: relative;
  right: 12px !important;
}

.h-582{
  height: 582px;
}
.move-down-100px{
  position: relative;
  top: 100px;
}
.commission-input-size{
  width: 110px;
}
.commission-input-size-v2{
  width: 212px;
}

@media screen and (min-max: 480px){
  .commission-input-size{
    width: 80px;
  }
  .commission-input-size-v2{
    width: 80px;
  }
}